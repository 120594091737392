console.log('dom loaded');

//// let domainsForOrder before registration function

/////////// Left menu
function openCloseleftMenu(){

    let menuContainer = document.querySelector('.ds-left-menu-container');
    let menu = document.querySelector('.ds-left-menu-container > .ds-menu');
    let button = document.querySelector('.ds-left-menu-container > .ds-button');
    let body = document.body;

    if(menuContainer.style.getPropertyValue('display') === 'block'){

        menuContainer.style.backgroundColor = 'transparent';
        menu.style.transform =  'translateX(-100%)';
        body.style.overflow = '';

        button.style.transform = 'rotate(90deg)';
        button.style.opacity = '0';

        const close = () => menuContainer.style = 'display: none;';
        setTimeout(close, 300);

    }
    else{

        menuContainer.style.backgroundColor = 'transparent';
        menuContainer.style.display = 'block';
        body.style.overflow = 'hidden';

        const open = () => {menuContainer.style.backgroundColor = '';
         menu.style.transform =  'unset'; button.style.transform = 'rotate(180deg)'; button.style.opacity = '1';}

        setTimeout(open, 0);

    }

}

/////////// Style switch

document.querySelector('#darkmode_switch').addEventListener('change', function(){
    let mode = 'none';
    let e = document.querySelector('#darkmode_switch'); 

    if(e.checked){

        mode = 'dark';
        console.log('dark');

    }
    else{
        
        mode = 'none';
        console.log('none');

    }

    setTimeout(function() { changeTheme(mode) }, 400);

});

function changeTheme(mode){

    $.ajax({
        url: '/style_switch',
        type: 'POST',
        data : {
            style : mode,
        },
        success: function(response){
            console.log(response);
            location.reload();
        }
    });
}

/////////// Right menu
function openCloseRightMenu(){

    let menuContainer = document.querySelector('.ds-right-menu-container');
    let menu = document.querySelector('.ds-right-menu-container > .ds-menu');
    let button = document.querySelector('.ds-right-menu-container > .ds-button');
    let body = document.body;

    if(menuContainer.style.getPropertyValue('display') === 'block'){

        menuContainer.style.backgroundColor = 'transparent';
        menu.style.transform =  '';
        body.style.overflow = '';

        button.style.transform = '';
        button.style.opacity = '0';


        const close = () => menuContainer.style = 'display: none;';
        setTimeout(close, 300);


    }
    else{

        menuContainer.style.backgroundColor = 'transparent';
        menuContainer.style.display = 'block';
        body.style.overflow = 'hidden';

        const open = () => {menuContainer.style.backgroundColor = '';
         menu.style.transform =  'unset'; button.style.transform = 'rotate(0deg)'; button.style.opacity = '1';}

        setTimeout(open, 0);

    }

}

///////////Registration and Log in forms

function openLoginForm(){

    let form = document.querySelector('.ds-autorization-form-wrap');

    if(form.style.getPropertyValue('display') === 'block'){

        form.style.opacity = '0';

        const close = () => form.style = 'display: none;';
        setTimeout(close, 300);


    }
    else{

        form.style.display = 'block';

        const open = () => {form.style.opacity = '1';}
        setTimeout(open, 0);

    }

}
function closeRegistrForm(){

    let Regform = document.querySelector('.ds-registration-form-wrap');

    Regform.style.opacity = '0';

    const close = () => Regform.style = 'display: none;';
    setTimeout(close, 300);

}

function openRegistrForm(){

    let messageDiv = document.querySelector('.ds-registr-form .ds-message');
    messageDiv.className = '';
    messageDiv.classList.add('ds-message');

    let Logform = document.querySelector('.ds-autorization-form-wrap');
    let Regform = document.querySelector('.ds-registration-form-wrap');

    Logform.style.opacity = '0';

    const close = () => Logform.style = 'display: none;';
    setTimeout(close, 300);


    Regform.style.display = 'block';

    const open = () => {Regform.style.opacity = '1';}
    setTimeout(open, 0);
}

function backToLogin(){
    let Logform = document.querySelector('.ds-autorization-form-wrap');
    let Regform = document.querySelector('.ds-registration-form-wrap');

    Regform.style.opacity = '0';

    const close = () => Regform.style = 'display: none;';
    setTimeout(close, 300);


    Logform.style.display = 'block';

    const open = () => {Logform.style.opacity = '1';}
    setTimeout(open, 0);

    closeForgotPass();
}

if(document.body.contains(document.querySelector('.ds-registr-form'))){

    /////////// Registration
    function validateEmail(email) {
        const re = new RegExp(/^([\w\!\#$\%\&\'\*\+\-\/\=\?\^\`{\|\}\~]+\.)*[\w\!\#$\%\&\'\*\+\-\/\=\?\^\`{\|\}\~]+@((((([a-z0-9]{1}[a-z0-9\-]{0,62}[a-z0-9]{1})|[a-z])\.)+[a-z]{2,6})|(\d{1,3}\.){3}\d{1,3}(\:\d{1,5})?)$/i);
        return re.test(email);
    }

    document.querySelector('.ds-registr-form input[name="email"]').addEventListener('input', function(){
        let email = document.querySelector('.ds-registr-form input[name="email"]');
        email.value.toLowerCase();
        if(validateEmail(email.value)){
            email.style.border = '';
        }
        else{
            email.style.border = '2px solid #ff3300';
        }
    });

    document.querySelector('.ds-registr-form input[name="login"]').addEventListener('input', function(){
        let name = document.querySelector('.ds-registr-form input[name="login"]');

        if(name.value.length >= 2){
            name.style.border = '';
        }
        else{
            name.style.border = '2px solid #ff3300';
        }
    });

    document.querySelector('.ds-registr-form input[name="password_confirm"]').addEventListener('input', function(){
        let password_confirm = document.querySelector('.ds-registr-form input[name="password_confirm"]');
        let password = document.querySelector('.ds-registr-form input[name="password"]').value;

        if(password_confirm.value != password){
            password_confirm.style.border = '2px solid #ff3300';
        }
        else{
            password_confirm.style.border = '';
        }
    });

    document.querySelector('.ds-registr-form input[name="password"]').addEventListener('input', function(){
        let password_confirm = document.querySelector('.ds-registr-form input[name="password_confirm"]');
        let password = document.querySelector('.ds-registr-form input[name="password"]');

        password.style.border = '';
        password = password.value;

        if(password_confirm.value != password){
            password_confirm.style.border = '2px solid #ff3300';
        }
        else{
            password_confirm.style.border = '';
        }
    });

    function closePopUp (){
        let popUp = document.querySelector('.ds-after-registr-popup-wrap');

        popUp.style.opacity = '0';
        const closePopUp = () => popUp.style = 'display: none;';
        setTimeout(closePopUp, 300);
    }

    function googleRegistr(googleUser) {

        let popUp = document.querySelector('.ds-after-registr-popup-wrap');
        let regform = document.querySelector('.ds-registration-form-wrap');

        // Useful data for your client-side scripts:
        var profile = googleUser.getBasicProfile();

        // The ID token you need to pass to your backend:
        var id_token = googleUser.getAuthResponse().id_token;

        $.ajax({
            url: '/client-register',
            type: 'POST',
            dataType: 'text',
            data : {
                name : profile.getGivenName(),
                email: profile.getEmail().toLowerCase(),
                avatar: profile.getImageUrl(),
                password: id_token,
                password_confirm: id_token,
                registr_type: 'google'
            },
            success: function(response){

                let messageDiv = document.querySelector('.ds-registr-form .ds-message');
                messageDiv.className = '';
                messageDiv.classList.add('ds-message');

                console.log(response);
                response = JSON.parse(response);
                

                if(response.created == true){
                    
                    // close registr form
                    regform.style.opacity = '0';
                    const closeReg = () => regform.style = 'display: none;';
                    setTimeout(closeReg, 300);

                    // open popUp
                    popUp.style.display = 'block';
                    const open = () => {popUp.style.opacity = '1';}
                    setTimeout(open, 0);

                    setTimeout(closePopUp, 2500);
                }
                else{
                    messageDiv.innerHTML = response.error;
                    messageDiv.classList.add('ds-error');
                }
                googleSignOut();
            }
        });
    }

    function registrNewUser(){

        let popUp = document.querySelector('.ds-after-registr-popup-wrap');
        let regform = document.querySelector('.ds-registration-form-wrap');

        let name = document.querySelector('.ds-registr-form input[name="login"]');
        let email = document.querySelector('.ds-registr-form input[name="email"]');
        let password = document.querySelector('.ds-registr-form input[name="password"]');
        let password_confirm = document.querySelector('.ds-registr-form input[name="password_confirm"]');

        let emptyCheck = {
            name: name,
            email: email.toLowerCase(),
            password: password,
            password_confirm: password_confirm
        }

        for (const [key, element] of Object.entries(emptyCheck)) {
            let e = element;
            if(e.value == null || e.value == ''){
                e.style.border = '2px solid #ff3300';
            }
        }

        if((password_confirm.value == password.value) && (name.value.length >= 2) && (validateEmail(email.value))){

            email.value;
            heap.identify(email);
            heap.addUserProperties({
                'Account Email': email.value
            });
            heap.track('User Registration');
            ////////////////////////////


            $.ajax({
                url: '/client-register',
                type: 'POST',
                dataType: 'text',
                data : {
                    name : name.value,
                    email: email.value,
                    password: password.value,
                    password_confirm: password_confirm.value,
                    registr_type: 'manual'
                },
                success: function(response){

                    let messageDiv = document.querySelector('.ds-registr-form .ds-message');
                    messageDiv.className = '';
                    messageDiv.classList.add('ds-message');

                    // console.log(response);
                    response = JSON.parse(response);
                    

                    if(response.created == true){
                        // messageDiv.innerHTML = response.text;
                        // messageDiv.classList.add('ds-succes');

                        // close registr form
                        regform.style.opacity = '0';
                        const closeReg = () => regform.style = 'display: none;';
                        setTimeout(closeReg, 300);

                        // open popUp
                        popUp.style.display = 'block';
                        const open = () => {popUp.style.opacity = '1';}
                        setTimeout(open, 0);

                        setTimeout(closePopUp, 4000);
                    }
                    else{
                        messageDiv.innerHTML = response.error;
                        messageDiv.classList.add('ds-error');
                    }
                    googleSignOut();
                }
            });
            
        }
    }

    /////////// Login
   
    document.querySelector('.ds-login-form input[name="password"]').addEventListener('input', function(){
        let e = document.querySelector('.ds-login-form input[name="password"]');
        e.style.border = '';
    });

    document.querySelector('.ds-login-form input[name="email"]').addEventListener('input', function(){
        let e = document.querySelector('.ds-login-form input[name="email"]');
        e.style.border = '';
    });

    function googleLogin(googleUser){
        // Useful data for your client-side scripts:
        var profile = googleUser.getBasicProfile();

        console.log("Email: " + profile.getEmail());

        // The ID token you need to pass to your backend:
        var id_token = googleUser.getAuthResponse().id_token;

        $.ajax({
            url: '/client-singin',
            type: 'POST',
            dataType: 'text',
            data : {
                email: profile.getEmail().toLowerCase(),
                avatar: profile.getImageUrl(),
                password: id_token,
                registr_type: 'google'
            },
            success: function(response){
                if(response == 'refresh'){
                    // document.location.reload();
                    document.location.href = "/my-domains";
                }
                else{
                    
                    response = JSON.parse(response);

                    if(response.correct != true){
                        messageDiv.innerHTML = response.error;
                        messageDiv.classList.add('ds-error');
                    }

                }
                googleSignOut();
            }
        });
    }

    function loginUser(){
        let email = document.querySelector('.ds-login-form input[name="email"]');
        let password = document.querySelector('.ds-login-form input[name="password"]');

        let emptyCheck = {
            password: password,
            email: email
        }

        for (const [key, element] of Object.entries(emptyCheck)) {
            let e = element;
            if(e.value == null || e.value == ''){
                e.style.border = '2px solid #ff3300';
            }
        }

        if(email.value.length != 0 && password.value.length != 0){

            email.value;
            heap.identify(email.value);
            heap.addUserProperties({
                'Account Email': email.value
            });
            heap.track('Autorization');
            ////////////////////////////

            let messageDiv = document.querySelector('.ds-login-form .ds-message');
            messageDiv.className = '';
            messageDiv.classList.add('ds-message');

            $.ajax({
                url: '/client-singin',
                type: 'POST',
                dataType: 'text',
                data : {
                    email: email.value.toLowerCase(),
                    password: password.value
                },
                success: function(response){
                    if(response == 'refresh'){
                        // document.location.reload();
                        document.location.href = "/my-domains";
                    }
                    else{
                        
                        response = JSON.parse(response);

                        if(response.correct != true){
                            messageDiv.innerHTML = response.error;
                            messageDiv.classList.add('ds-error');
                        }

                    }
                    googleSignOut();
                }
            });
        }
        else{
            // console.log('empty');
        }
    }

    /////////// Logout
    function googleSignOut() {
        var auth2 = gapi.auth2.getAuthInstance();
        auth2.signOut().then(function () {
          console.log('User signed out.');
        });
    }

    function logout(){
        $.ajax({
            url: '/client-logout',
            type: 'POST',
            success: function(response){
                if(response == 'refresh'){
                    // document.location.reload();
                    document.location.href = "/";
                }
                else{
                    // console.log(response);
                }
            }
        });
    }

    /////////// forgot pass
    function closeForgotPass(){
        let forgotPassForm = document.querySelector('.ds-forgot-pass-wrap');

        forgotPassForm.style.opacity = '0';

        const close = () => forgotPassForm.style = 'display: none;';
        setTimeout(close, 300);
    }

    function openForgotPass(){

        let Logform = document.querySelector('.ds-autorization-form-wrap');
        let forgotPassForm = document.querySelector('.ds-forgot-pass-wrap');

        Logform.style.opacity = '0';

        const close = () => Logform.style = 'display: none;';
        setTimeout(close, 300);


        forgotPassForm.style.display = 'block';

        const open = () => {forgotPassForm.style.opacity = '1';}
        setTimeout(open, 0);
    }

    document.querySelector('.ds-forgot-pass input').addEventListener('input', function(){
        let email = document.querySelector('.ds-forgot-pass input');
        email.value.toLowerCase();
        if(validateEmail(email.value)){
            email.style.border = '';
        }
        else{
            email.style.border = '2px solid #ff3300';
        }
    });

    function forgotPass(){

        let email = document.querySelector('.ds-forgot-pass input');
        let message = document.querySelector('.ds-forgot-pass .ds-message');
        message.className = '';
        message.classList.add('ds-message');


        if(validateEmail(email.value)){
            $.ajax({
                url: '/forgot_pass',
                type: 'GET',
                dataType: 'text',
                data : { email: email.value.toLowerCase() },
                success: function(response){
                    if(response != ''){
                        message.innerHTML = response;
                        console.log(response);
                        message.classList.add('ds-succes');
                    }
                    else{
                        console.log('pizda');
                    }
                }
            });
        }
        else{
            email.style.border = '2px solid #ff3300';
        }
        
    }
    
}